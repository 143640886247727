<template>
  <CommonPage id="privacy" title="Privacy Policy">
    <section class="privacy-content">
      <div class="c-text_wrap cc-legal">
        <p class="c-text_legal">
          Protecting the privacy of visitors to our website is of utmost importance to us. We are
          highly dedicated to safeguarding user privacy. The goal of this privacy policy is to ring
          clarity to how we manage the personal information of our website visitors.
        </p>

        <p class="c-text_legal">
          Agreeing to this privacy policy implies the user&#39;s acceptance to the utilization of
          cookies according to the terms and conditions stated in this policy.
        </p>

        <p class="c-text_legal">
          This privacy policy outlines how information is collected, circulated, and utilized. These
          processes are applicable to this website. It also includes its affiliates and
          subsidiaries,
          and the information disclosure, storage, collection, and use.
        </p>

        <p class="c-text_legal">
          The terms and/or conditions of this privacy policy may be changed, modified, or both at
          any
          time. The user will be sent a notification about it. Additionally, any modification or
          alteration to the privacy policy may be posted through the user&#39;s registered email
          address. In case the user refuses to accept any of the changes made to the privacy policy,
          the user must immediately stop using the website. If the user continues the use of the
          website, it implies that their consent to the changed or modified terms and conditions of
          the privacy policy.
        </p>

        <p class="c-text_legal">
          The website does not hold any liability for the information collection by any of the
          third-party clients, companies, or websites. This privacy policy is specifically
          associated
          with the information practices of this website. As this website includes links to other
          websites, the users must be aware that this website cannot be held accountable for the
          privacy policies and information collection and distribution processes of the websites
          that
          it may link to. In the event a user clicks on any of the stated links and is redirected to
          other websites, it is recommended to read the terms and policies of the other website as
          well.
        </p>

        <p class="c-text_legal">
          Collection of Personal Information
        </p>

        <p class="c-text_legal">
          Through the website, we may collect, store, and utilize the following types of
          information.
        </p>

        <p class="c-text_legal">
          (a) The information provided when registering with our website;<br>
          (b) any of the other personal information that is sent by the user to us;<br>
          (c) the information provided when subscribing to our email notifications and/or
          newsletters,
          including username and email ID;<br>
          (d) the information provided during the use of the services on our website, or information
          created while using such services. This may include frequency, timing, and pattern of
          service use;<br>
          (e) the information about a user&#39;s personal computer or laptop, the use of this
          website
          along with the (internet protocol) IP address, operating system, location, browser version
          and type, referral source, page views, duration of the visit, and website navigation
          paths;<br>
          (f) the information related to any purchase made on our website or any transactions the
          user
          enters into through our website. This may include the user&#39;s name, email address,
          postal
          address, telephone number, and debit or credit card details;<br>
          (g) the information provided while completing a user profile on the website. This includes
          the user&#39;s name, gender, address, date of birth, profile pictures, relationship
          status,
          interests and hobbies, education details, and employment details;<br>
          (h) user information posted on our website for publication on the Internet. Such
          information
          may include username, profile pictures, and other related content of the posts; and<br>
          (i) the information associated with any communication sent through our website or sent to
          us. This may include content and metadata related to the communication.
          Users must note that it is mandatory to obtain the consent of another person before
          disclosing the person&#39;s personal information to us.
        </p>

        <p class="c-text_legal">
          No Information Collected from Children:
          We hereby inform that our website is general in nature. It is created for and is supposed
          to
          be used by adults only. We never knowingly gather, ask, or store personal information from
          children. Our websites are not intended to be used by children, and therefore, we do not
          gather any information from users who are under 18 years of age. Safeguarding children and
          protecting their information is important to us, and therefore, parents are advised to
          thoroughly review this privacy policy and peruse the terms and conditions.
        </p>

        <p class="c-text_legal">
          Third-Party Advertising:
          Third-party companies provide third-party advertisements on our websites and other such
          websites where we advertise. These third-party companies use the information of user
          visits
          to our website. Whenever these advertisements are displayed to the user, the third-party
          companies place their unique cookies on the user&#39;s browser. Through these cookies, ads
          are displayed whenever users visit the site. Also, the website may utilize web beacons
          that
          are offered by third-party advertising companies for the supervising online
          advertisements.
          Using web beacons, the website can recognize the cookies as and when the user visits the
          website. The web beacons also allow the site to recognize banner ads that directed the
          user
          to the website.
        </p>

        <p class="c-text_legal">
          Web Beacons: These are programming code used for the display of an image on a web page.
          Web
          beacons are utilized to transfer the unique user identification of the user to a database.
          This unique user identification is usually in the form of a cookie, which is utilized to
          link the individual user to the acquired information in the database. Web beacons help in
          the tracking of individual online visits to the website. Such tracking enables the
          regulation of services and products that a user may be interested in. Also, this allows
          the
          tracking of online behavioral patterns of users for marketing purposes.
        </p>

        <p class="c-text_legal">
          Cookies: These are small data parts created by a web server. Cookies are distributed
          through a web browser and stored on the user&#39;s computer. These help in the tracking of
          the online patterns and preferences of the user. Additionally, cookies help to identify
          the
          user as a return visitor. These also create customization of a user&#39;s web experiences
          so
          the user does not need to add every information, every time they visit the website. The
          users visiting the website have to accept the cookies whenever they visit the site to get
          access to all the features.
        </p>

        <p class="c-text_legal">
          IP Address: The website gathers the user&#39;s IP address to ascertain problems with our
          server and manage issues. IP is the number assigned to a user&#39;s computer whenever they
          use the Internet. IP address is required to recognize a user during any online session.
          Also, it is used for the collection of data based on demographics.
        </p>

        <p class="c-text_legal">
          Usage Tracking and Log Files: The website tracks associated information of all the web
          pages viewed by visitors. Also, the website tracks all the information related to every
          hyperlink clicked by the user as and when they visit any of our websites or while viewing
          our emails. Log files are utilized for tracking and monitoring of member usage.
        </p>

        <p class="c-text_legal">
          Use of Personal information:
          The personal information received by us via the website shall be used only for specific
          purposes mentioned in the policy.
        </p>

        <p class="c-text_legal">
          We may utilize the personal information provided by the user to:
        </p>

        <p class="c-text_legal">
          (a) manage the website and business;<br>
          (b) make modifications to the website;<br>
          (c) allow the use of website services;<br>
          (d) send the goods to the user purchased on our website;<br>
          (e) offer the user the services purchased on our website;<br>
          (f) send statements, invoices, and reminders of payments to the user;<br>
          (g) send user notices through emails, which are specifically requested by the user;<br>
          (h) collect payments from the user;<br>
          (i) send our email newsletter, in case these are specifically requested by the user (the
          user may also notify us in case they do not want to receive newsletters);<br>
          (j) provide information on statistics to third parties about the users (the third parties
          will not be able to recognize any particular user from the information provided);<br>
          (k) send non-marketing communications;<br>
          (l) secure and safeguard the website from any fraudulent activities;<br>
          (m) send marketing communications connected to our business either by post or by email,
          which has been specifically requested by the user (the user can notify us to stop sending
          these marketing communications if they no longer wish to receive these in the future);<br>
          (n) check compliance with the terms and conditions governing the use of the website (this
          also includes monitoring and scrutinizing the private messages sent through the private
          messaging service of the website); and<br>
          (o) deal with complaints and enquiries made by or about the user to the website.
          It should be noted here that no part of user personal information will be published on
          third-party companies for purposes such as direct marketing, without a consensus.
        </p>

        <p class="c-text_legal">
          In the event a user provides any personal information to be published on the website, we
          shall publish the content and/or use the furnished data in concurrence with the license
          that
          the user has allowed to us. The user can use the privacy settings to stop the distribution
          of the personal information provided to our website. This can be changed using the privacy
          controls on the site.
        </p>

        <p class="c-text_legal">
          All the financial transactions related to the website are managed by our payment service
          provider. We can share any personal information given by a user with our payment service
          provider for purposes including complaints, queries, and payments, and refunds.
        </p>

        <p class="c-text_legal">
          Release of Personal Information:
          We may release a user&#39;s personal information to any of the members of our group of
          companies. These can include our holding company as well subsidiaries. The release of
          user&#39;s personal information may be required for certain cases that are stated in this
          privacy policy.
        </p>

        <p class="c-text_legal">
          We may also disclose a user&#39;s personal information to any of insurers, agents,
          officers, employers, professional advisers, subcontractors, or suppliers for certain
          purposes stated in this privacy policy.
        </p>

        <p class="c-text_legal">
          We may disclose users&#39; personal information:
          (a) to the extent that may be required by law; <br>
          (b) to a buyer (or a prospective buyer) of any business that we are (or are considering)
          selling;<br>
          (c) when it comes to any ongoing or prospective legal proceedings;<br>
          (d) to exercise, establish, or protect our legal rights (including providing information
          to
          others for the purposes of prevention of fraudulent activities and falling credit risk),
          and<br>
          (e) to any person, who we think may apply to a court or other capable authority for
          disclosing that personal information where, in our reasonable opinion, such court or
          authority would be reasonably likely to order reveal of that personal information.
          We shall not share or disclose any user&#39;s personal information to third parties,
          except
          in cases that are stated in this privacy policy.
        </p>

        <p class="c-text_legal">
          Changes to this Privacy Policy:
          We reserve the right to alter or modify any of the provisions of this privacy policy.
          Users
          are suggested to regularly check the privacy policy for any updated changes and
          alterations.
          In case of any material changes made to this policy, the user will be sent notifications
          either to the registered email ID or by placing a permanent notice on the website.
        </p>

        <p class="c-text_legal">
          Unless there are no further changes made to this privacy policy, it continues to be in
          effect. In the event that we make any changes to the privacy policy in the future, we
          would
          bring them into effect and notify the user about it. The continued use of the website
          after
          the changes are made to the privacy policy will reflect the user&#39;s acknowledgement of
          these changes and the user&#39;s consent to bear and be bound by the modified changes.
        </p>

        <p class="c-text_legal">
          Security:
          The website uses industry-standard guidelines for protecting and safeguarding
          individual data. Also, the website uses standard safety measures to prevent the
          misuse, change, or loss of data. Users are informed that the storage technique, or the
          measure of transmitted information over the Internet is not completely secure. Although we
          do our best to save and shield our users&#39; personal information, we can&#39;t ensure
          its
          complete security. Also, while we ensure to take all efforts to secure the networks and
          systems, we do not provide any guarantee that these security measures would stop any
          third-party hackers from unlawfully accessing the personal information.
        </p>
      </div>
    </section>
  </CommonPage>
</template>

<script>
import '@/css/news/privacyterms.scss';
import CommonPage from './CommonPage.vue';
export default {
  components: {
    CommonPage
  },
};
</script>
